import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Donate from '../components/donation/Donate';
import Banner from '../components/shared/Banner';
import OurNumbers from '../components/donors/OurNumbers';
import OurDonors from '../components/donors/OurDonors';
import './donors-page.scss';

export const DonorsPageTemplate = ({ banner, why_donate, our_numbers, donors }) => {
  return (
    <div className="donors">
      <Banner {...banner}></Banner>
      <Donate />
      <OurNumbers ourNumbers={our_numbers} />
      <OurDonors donors={donors} />
    </div>
  );
};

DonorsPageTemplate.propTypes = {
  banner: PropTypes.object,
  our_numbers: PropTypes.object,
  donors: PropTypes.object,
};

const DonorsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <DonorsPageTemplate banner={frontmatter.banner} why_donate={frontmatter.why_donate} our_numbers={frontmatter.our_numbers} donors={frontmatter.donors} />
    </Layout>
  );
};

DonorsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default DonorsPage;

export const donorsPageQuery = graphql`
  query donorsPageAndDonorsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          subtitle
        }
        our_numbers {
          donors
          aum
        }
        donors {
          title
          subtitle
          descriptionA
          descriptionB
          donors_groups {
            title
            enable
            profiles {
              name
              description
            }
          }
        }
      }
    }
  }
`;
