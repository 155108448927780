import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import './OurDonors.scss';


const OurDonors = ({ donors }) => {
  const [accordion, setAccordion] = useState(donors.donors_groups);
  const handleAccordion = (data) => {
    data.enable = !data.enable;
    setAccordion([...accordion]);
  };

  const profileInfo = (profile, index) => {
    return (
      <div className="our-donors_info_content" key={index}>
        <div>
          <p className="our-donors_info_content_title">{profile.name}</p>
          <span className="our-donors_info_content_office">{profile.description}</span>
        </div>
      </div>
    );
  }

  return (
    <section className="our-donors">
      <div className="container">
        <h2 className="our-donors_title text-primary">{donors.title}</h2>
        <p className="our-donors_description">{donors.subtitle}</p>
        <p className="our-donors_text">
          <span>{donors.descriptionA}</span>
          <br/>
          <br/>
          <span>{donors.descriptionB}</span>
        </p>
      </div>

      <div className="container">
        <div className="accordion">
          {donors.donors_groups.map((donors_group, index) => {
            return (
              <React.Fragment>
                <div className="mb-25">
                  <div
                    className="our-donors_info"
                    aria-hidden="true"
                    role="button"
                    onClick={() => handleAccordion(donors_group)} >
                    <div className="our-donors_info_title">{donors_group.title}</div>
                    <div className="our-donors_info_icon">
                      <FontAwesomeIcon icon={donors_group.enable ? faAngleUp : faAngleDown} />
                    </div>
                  </div>
                  <div className="our-donors_info_accordion">
                    {donors_group.enable &&
                        donors_group.profiles.map((profile, i) => profileInfo(profile, i)) }
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};

OurDonors.propTypes = {
  donors: PropTypes.shape({
    descriptionA: PropTypes.string,
    descriptionB: PropTypes.string,
    donors_group: PropTypes.arrayOf({
      title: PropTypes.string,
      enable: PropTypes.bool,
      profiles: PropTypes.arrayOf({
        name: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  }),
};

export default OurDonors;
