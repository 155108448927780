import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import './Donate.scss';

const Donate = ({ data }) => {
  const { edges: donates } = data.allDonateJson;
  console.log({ data, donates })

  let getImage = (image) => {
    return image.childImageSharp ? image.childImageSharp.fluid.src : image;
  };
  return (
    <section className="section section--gradient">
      <div className="container why-donate">
        <h2 className="text-primary">POR QUE DOAR?</h2>
        <div className="d-flex justify-content-center mb-25">
          {donates.map((donate) => {
            const { image, text } = donate.node;
            return (
              <React.Fragment>
                <div className="why-donate_card">
                  <img src={getImage(image)}></img>
                  <p className="why-donate_description">{text}</p>
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <div className="why-donate_footer">
          <p>
            Toda doação é bem-vinda. Nenhuma doação é pequena ou grande demais.
          </p>
          <a href="https://doe.amigosdapoli.com.br" target="_blank">
            doe agora
          </a>
        </div>
      </div>
    </section>
  );
};

Donate.propTypes = {
  data: PropTypes.shape({
    allDonateJson: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query DonateQuery {
        allDonateJson {
          edges {
            node {
              id
              image
              text
            }
          }
        }
      }
    `}
    render={(data) => <Donate data={data} />}
  />
);
