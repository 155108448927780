import React from 'react';
import PropTypes from 'prop-types';

import './OurNumbers.scss';

const OurNumbers = ({ ourNumbers }) => {
  return (
    <section className="our-numbers">
      <h2 className="our-numbers_title">NOSSOS NÚMEROS</h2>
      <p className="our-numbers_discription">Fomentamos a inovação e a educação de ponta na Poli.</p>
      <div className="d-flex justify-content-center">
        <div className="our-numbers_info">
          <p className="our-numbers_info_number">{ourNumbers.donors}</p>
          <p className="our-numbers_info_description">doadores</p>
        </div>
        <div className="our-numbers_info">
          <p className="our-numbers_info_number">{ourNumbers.aum}</p>
          <p className="our-numbers_info_description">de patrimônio</p>
        </div>
      </div>
    </section>);
};

OurNumbers.propTypes = {
  ourNumbers: PropTypes.shape({
    donors: PropTypes.number.isRequired,
    aum: PropTypes.number.isRequired,
  }),
};

export default OurNumbers;
